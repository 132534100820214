import * as TabPrimitives from "@radix-ui/react-tabs";
import { forwardRef } from "react";
import { cn } from "../../utils/cn";

/**
 * Tab panels
 * Based on:
 * https://www.radix-ui.com/docs/primitives/components/tabs
 */

const Base = TabPrimitives.Root;
const TabList = forwardRef<HTMLDivElement, TabPrimitives.TabsListProps>(
  ({ className, ...props }, ref) => (
    <TabPrimitives.List
      {...props}
      className={cn("flex gap-6", className)}
      ref={ref}
    />
  )
);

const Tab = forwardRef<HTMLButtonElement, TabPrimitives.TabsTriggerProps>(
  ({ className, ...props }, ref) => (
    <TabPrimitives.Trigger
      {...props}
      className={cn(
        "cursor-pointer",
        "data-[state=active]:font-semibold",
        className
      )}
      ref={ref}
    />
  )
);
const Content = TabPrimitives.Content;

export const Tabs = {
  Base,
  TabList,
  Tab,
  Content,
};
