import * as SelectPrimitives from "@radix-ui/react-select";
import { ReactNode } from "react";
import { cn } from "../../utils/cn";
import { Text } from "../Text/Text";

type SelectProps = {
  children: ReactNode;
} & Omit<SelectPrimitives.SelectProps, "open">;

/**
 * Basic Dropdown Menu.
 * Based on:
 * https://www.radix-ui.com/docs/primitives/components/dropdown-menu
 */
const Base = ({ children, ...props }: SelectProps) => {
  return <SelectPrimitives.Root {...props}>{children}</SelectPrimitives.Root>;
};

const Item = ({
  className,
  children,
  ...rest
}: SelectPrimitives.SelectItemProps) => (
  <SelectPrimitives.Item
    {...rest}
    className={cn(
      "px-4 py-2 rounded-lg",
      "data-[highlighted]:bg-slate-100 data-[highlighted]:outline-none"
    )}
  >
    <SelectPrimitives.ItemText>
      <Text>{children}</Text>
    </SelectPrimitives.ItemText>
    <SelectPrimitives.ItemIndicator />
  </SelectPrimitives.Item>
);

const Trigger = (props: SelectPrimitives.SelectValueProps) => (
  <SelectPrimitives.Trigger
    className={cn([
      "w-full no-underline whitespace-nowrap border border-slate-300 outline-cyan-600/50",
      "transition-all duration-200 ease-in-out",
      "hover:bg-cyan-100/10 hover:border-cyan-600/50",
      "focus:bg-cyan-100/10 focus:border-cyan-600/50",
      "placeholder:text-slate-400",
      "rounded-2xl text-slate-800 px-6 font-normal",
    ])}
  >
    <SelectPrimitives.Value {...props} />
  </SelectPrimitives.Trigger>
);

const Content = ({
  className,
  children,
  ...rest
}: SelectPrimitives.SelectContentProps) => (
  <SelectPrimitives.Portal>
    <SelectPrimitives.Content
      className={cn(
        "overflow-hidden",
        "bg-white rounded-2xl shadow-lg p-2",
        className
      )}
      {...rest}
    >
      <SelectPrimitives.Viewport>{children}</SelectPrimitives.Viewport>
    </SelectPrimitives.Content>
  </SelectPrimitives.Portal>
);

type StyledSelectProps = {
  children: ReactNode;
  placeholder: SelectPrimitives.SelectValueProps["placeholder"];
  className?: string;
} & SelectPrimitives.SelectProps;

/**
 * Styled select component.
 * Takes a list of `Select.Item` as children.
 */
export const StyledSelect = ({
  placeholder,
  children,
  className,
  ...props
}: StyledSelectProps) => (
  <SelectPrimitives.Root {...props}>
    <SelectPrimitives.Trigger
      className={cn([
        "h-12 w-full no-underline whitespace-nowrap border border-slate-300 outline-cyan-600/50",
        "transition-all duration-200 ease-in-out",
        "hover:bg-cyan-100/10 hover:border-cyan-600/50",
        "focus:bg-cyan-100/10 focus:border-cyan-600/50",
        "placeholder:text-slate-400",
        "rounded-2xl text-slate-800 px-6 font-normal",
        "flex items-center justify-between",
        className,
      ])}
    >
      <SelectPrimitives.Value placeholder={placeholder} />
      <SelectPrimitives.Icon />
    </SelectPrimitives.Trigger>
    <SelectPrimitives.Portal>
      <SelectPrimitives.Content
        className={cn("overflow-hidden", "bg-white rounded-2xl shadow-lg p-2")}
      >
        <SelectPrimitives.ScrollUpButton />
        <SelectPrimitives.Viewport className={"p-2"}>
          {children}
        </SelectPrimitives.Viewport>
        <SelectPrimitives.ScrollDownButton />
        <SelectPrimitives.Arrow />
      </SelectPrimitives.Content>
    </SelectPrimitives.Portal>
  </SelectPrimitives.Root>
);

/**
 * Component primitives
 */
export const Select = {
  Base,
  Trigger,
  Item,
  Content,
};
