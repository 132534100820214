import clsx, { ClassValue } from "clsx";
// Remove the import for the CSS module
// import { separator } from "./style.css";

type SeparatorProps = {
  className?: ClassValue;
};

export const Separator = ({ className }: SeparatorProps) => {
  return (
    <hr
      className={clsx(
        "w-full h-px border-0 border-b border-solid border-slate-200",
        className
      )}
    />
  );
};
