import { Text, LinkButton } from "@packages/ui";
import { useRouter } from "next/router";
import { Locale } from "services/strings";

export const LanguagePicker = () => {
  const router = useRouter();

  const setLocale = (locale: Locale) => {
    const { pathname, asPath, query } = router;
    document.cookie = `NEXT_LOCALE=${locale}; maxage=${
      1000 * 60 * 60 * 24 * 7
    }; path=/`;
    router.push({ pathname, query }, asPath, { locale });
  };

  return (
    <div>
      <div>
        <LinkButton onClick={() => setLocale("en-EG")}>EN</LinkButton>
        <Text> | </Text>
        <LinkButton onClick={() => setLocale("ar-EG")}>عربي</LinkButton>
      </div>
    </div>
  );
};
