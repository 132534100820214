import * as SliderPrimitive from "@radix-ui/react-slider";
import { cn } from "../../utils/cn";

export const Slider = ({ ...props }) => {
  return (
    <SliderPrimitive.Root
      className={cn(
        "relative flex items-center select-none touch-none w-full h-5"
      )}
      {...props}
    >
      <SliderPrimitive.Track
        className={cn("bg-slate-200 relative flex-grow rounded-full h-[3px]")}
      >
        <SliderPrimitive.Range
          className={cn("absolute bg-slate-700 rounded-full h-full")}
        />
      </SliderPrimitive.Track>
      <SliderPrimitive.Thumb
        className={cn(
          "block w-5 h-5 bg-slate-700 rounded-[10px] border-2 border-white",
          "focus:outline-none focus:shadow-[0_0_0_2px_rgba(0,0,0,0.3)]"
        )}
      />
    </SliderPrimitive.Root>
  );
};
