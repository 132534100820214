/**
 * Design Tokens
 */

/**
 * Breakpoint tokens, adapted from TailwindCSS
 */

const breakpoint = {
  sm: 0,
  md: 768,
  lg: 1024,
  xl: 1280,
} as const;

export type Breakpoint = keyof typeof breakpoint;
export const breakpointNames = Object.keys(breakpoint) as Breakpoint[];

// export
export { breakpoint };
