import { cva, VariantProps } from "class-variance-authority";
import { ClassValue } from "clsx";
import {
  ComponentProps,
  forwardRef,
  ForwardRefRenderFunction,
  HTMLInputTypeAttribute,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { cn } from "../../utils/cn";

const inputVariants = cva("", {
  variants: {
    size: {
      large: ["px-6 h-16"],
      small: ["px-4 h-12"],
    },
  },
  defaultVariants: {
    size: "small",
  },
});

type InputVariants = VariantProps<typeof inputVariants>;

const selectionTypes: readonly HTMLInputTypeAttribute[] = [
  "text",
  "search",
  "url",
  "tel",
  "password",
] as const;

const isSelectionType = (type: unknown): Boolean =>
  typeof type === "string" &&
  selectionTypes.indexOf(type as HTMLInputTypeAttribute) > -1;

export interface InputHandle {
  focus: () => void;
  setSelectionRange: (start: number, end: number) => void;
}

export type InputProps = InputVariants & {
  className?: ClassValue;
} & Omit<ComponentProps<"input">, "size">;

const BaseInput: ForwardRefRenderFunction<InputHandle, InputProps> = (
  { className, size, ...props },
  forwardedRef
) => {
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!isSelectionType(props.type) || !ref.current) {
      return;
    }

    const index = ref.current.value.length;
    ref.current.setSelectionRange(index, index);
  }, [ref]);

  useImperativeHandle(
    forwardedRef,
    (): InputHandle =>
      ref.current ?? {
        focus: () => void 0,
        setSelectionRange: () => void 0,
      },
    [ref.current]
  );

  return (
    <input
      {...props}
      className={cn(
        inputVariants({ size }),
        "w-full no-underline whitespace-nowrap border border-slate-300 outline-cyan-600/50",
        "transition-all duration-200 ease-in-out",
        "hover:bg-cyan-100/10 hover:border-cyan-600/50",
        "focus:bg-cyan-100/10 focus:border-cyan-600/50",
        "placeholder:text-slate-400",
        "rounded-2xl text-slate-800 px-6 font-normal",
        className
      )}
      ref={ref}
    />
  );
};

export const Input = forwardRef(BaseInput);

// ... existing imports ...
