import { LanguagePicker } from "@components/LanguagePicker";
import { paths } from "@utils/paths";
import Image from "next/image";
import Link from "next/link";

export const Header = () => {
  return (
    <header className={"h-20 bg-white sticky top-0 z-10"}>
      <nav className={"mx-6 relative flex flex-row items-center h-full"}>
        <div>
          <Link href={paths.home}>
            <Image
              src={"/logo.svg"}
              height={27}
              width={77}
              alt={"mnzl logo"}
              className="absolute inset-0 flex items-center justify-center m-auto"
            />
          </Link>
          <LanguagePicker />
        </div>
      </nav>
    </header>
  );
};
