import { Slot } from "@radix-ui/react-slot";
import { cva, VariantProps } from "class-variance-authority";
import React from "react";
import { cn } from "../../utils/cn";

const textVariants = cva("", {
  variants: {
    variant: {
      default: "text-base font-normal",
      eyebrow: "text-base md:text-xl font-semibold uppercase tracking-wider",
      "section-heading": "text-3xl md:text-4xl font-semibold leading-tight",
      heading: "text-4xl md:text-5xl font-semibold leading-tight",
      subtitle: ["text-base", "md:text-lg", "font-normal", "text-slate-500"],
    },
    color: {
      default: "text-slate-800",
    },
    textAlign: {
      left: "text-left",
      center: "text-center",
      right: "text-right",
    },
    fontWeight: {
      default: "font-normal",
      semibold: "font-semibold",
      bold: "font-bold",
    },
    fontSize: {
      default: "",
      12: "text-xs",
      14: "text-sm",
      16: "text-base",
      18: "text-lg",
      20: "text-xl",
      24: "text-2xl",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

export const validTextTags = ["h1", "h2", "h3", "label", "span", "p"] as const;
type ValidTextTag = (typeof validTextTags)[number];

export interface TextBaseProps
  extends Omit<React.HTMLAttributes<HTMLSpanElement>, "color">,
    VariantProps<typeof textVariants> {
  asChild?: boolean;
  as?: ValidTextTag;
  color?: VariantProps<typeof textVariants>["color"];
}

const TextBase = React.forwardRef<any, TextBaseProps>(
  (
    {
      className,
      variant,
      fontSize,
      fontWeight,
      color,
      asChild = false,
      as,
      ...props
    },
    ref
  ) => {
    const Comp = as ? as : asChild ? Slot : "span";
    return (
      <Comp
        className={cn(
          textVariants({ variant, fontSize, color, fontWeight, className })
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
TextBase.displayName = "Text";

export { TextBase as Text };
