import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group";
import { cn } from "../../utils/cn";

//based on https://www.radix-ui.com/docs/primitives/components/toggle-group

const Base = ({
  children,
  type = "single",
  className,
  ...props
}: Omit<ToggleGroupPrimitive.ToggleGroupSingleProps, "type"> & {
  type?: "single";
}) => (
  <ToggleGroupPrimitive.Root
    className={cn(
      [
        "rounded-[8px]",
        "inline-flex",
        "justify-evenly",
        "bg-slate-100",
        "p-1",
        "min-h-[56px]",
      ],
      className
    )}
    {...props}
    type={type}
  >
    {children}
  </ToggleGroupPrimitive.Root>
);

const Item = ({
  children,
  className,
  ...props
}: ToggleGroupPrimitive.ToggleGroupItemProps) => (
  <ToggleGroupPrimitive.Item
    className={cn(
      [
        "bg-slate-100 text-slate-600",
        "max-w-full flex text-[14px] leading-none items-center justify-center",
        "ml-[1px] flex-1 min-h-[40px] px-1 whitespace-nowrap",
        "first:ml-0 first:rounded-tl-[4px] first:rounded-bl-[4px]",
        "last:rounded-tr-[4px] last:rounded-br-[4px]",
        "focus:z-[1]",
        "hover:bg-seaBlue85",
        "data-[state=on]:bg-slate-600 data-[state=on]:text-white data-[state=on]:rounded-[4px]",
      ],
      className
    )}
    {...props}
  >
    {children}
  </ToggleGroupPrimitive.Item>
);

export const ToggleGroup = {
  Base,
  Item,
};
