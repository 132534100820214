/**
 * Type safe paths for the app.
 */

import { QueryKey } from "@services/analytics";

export const paths = {
  car: {
    landing: "/car",
    preApproval: "/car/pre-approval",
    dashboard: "/car/dashboard",
  },
  home: "/",
  homeEquity: {
    landing: "/home-equity",
    preApproval: "/home-equity/pre-approval",
    dashboard: "/home-equity/dashboard",
  },
} as const;

/**
 * Appends known query params to a type-safe path.
 */
export const withQueryParams = (
  path: string,
  params: Record<QueryKey, string>
) => {
  // We are using URLSearchParams to encode the query params
  const _params = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    _params.append(key, value);
  });
  return `${path}?${_params.toString()}`;
};
