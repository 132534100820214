import { PropsWithChildren } from "react";
import { cn } from "../../utils/cn";

export const Card = ({
  children,
  className,
  ...rest
}: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) => {
  return (
    <div
      className={cn("relative rounded-2xl shadow-lg p-6", className)}
      {...rest}
    >
      {children}
    </div>
  );
};
